import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { makeUseAxios, Options, UseAxiosResult } from 'axios-hooks';
import { useEffect, useState } from 'react';

import { Model } from '../types/models';

export type SimplePaginationProps = {
  draw: string | number | null
  hasNext: boolean | null
};

interface ApiCollectionResponse<T extends Model> extends SimplePaginationProps {
  data: T[]
}

interface UseApiCollectionResult<T extends Model> extends SimplePaginationProps {
  items: T[]
}

export interface PaginationMeta extends SimplePaginationProps {
  size: number
  offsetValue: number
  limitValue: number
  totalCount: number
  currentPage: number | null
  nextPage: number | null
  prevPage: number | null,
  totalPages: number | null,
}

interface UsePaginatedApiCollectionResult<T extends Model> extends UseApiCollectionResult<T>, PaginationMeta {

}

interface PaginatedApiCollectionResponse<T extends Model> extends ApiCollectionResponse<T>, PaginationMeta {

}

/**
 * The api.client is a batteries included axios request client
 * to make sure the relevant headers are added before dispatch
 */
export const client = axios.create();

let csrfToken: string;

/**
 * Adds csrf header to all post requests with application/json
 */
client.interceptors.request.use((config: AxiosRequestConfig) => {
  // don't add csrf for these request methods
  const exemptedHTTPMethods = ['get', 'head', 'options'];
  if (exemptedHTTPMethods.includes(config.method.toLowerCase())) {
    return config;
  }

  const intercepted = { ...config };

  if (!csrfToken) {
    csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') || '';
  }

  intercepted.headers['X-CSRF-Token'] = csrfToken;

  return intercepted;
});

/**
 * Export a version of the useAxios hook that is configured with the default interceptors.
 */
export const useAxios = makeUseAxios({ axios: client });

export type AccumulationConfig = {
  accumulate?: boolean
};

type ApiCollectionConfig = AxiosRequestConfig & AccumulationConfig;

/**
 * Fetches items part of a collection. Assumes a CollectionResponse<T> is returned.
 *
 * Configurable with all axiosConfig (https://axios-http.com/docs/req_config) and
 * additionally provides some options to trune accumulation and pagination
 * behavior (AccumulationConfig and PaginationConfig).
 */
export function useApiCollection<T extends Model>(
  { accumulate, ...axiosConfig }: ApiCollectionConfig,
  options ?: Options,
): [UseApiCollectionResult<T>, ...UseAxiosResult] {
  const [accumulated, setAccumulated] = useState<T[]>([]);
  const [responseValues, execute, manualCancel] = useAxios(axiosConfig, options);
  const response = responseValues.response as AxiosResponse<ApiCollectionResponse<T>>;
  const { data: items, ...meta } = response?.data || { data: [], hasNext: null, draw: null };

  useEffect(() => {
    // if no response is yet in, skip
    if (!response) {
      return;
    }

    if (!accumulate) {
      setAccumulated(items);
      return;
    }

    // if accumulating but no items returned, skip
    if (items.length === 0) {
      return;
    }

    setAccumulated((value) => {
      // set is used to make sure we only accumulate unique items
      const itemset = new Set(value.map((item) => item.id));
      return [
        ...value,
        ...items.filter((item) => !itemset.has(item.id)),
      ];
    });
  }, [items, response]);

  return [{ items: accumulated, ...meta }, responseValues, execute, manualCancel];
}

export function usePaginatedApiCollection<T extends Model>(
  config: AxiosRequestConfig,
  options?: Options,
): [UsePaginatedApiCollectionResult<T>, ...UseAxiosResult] {
  const [responseValues, execute, manualCancel] = useAxios(config, options);
  const response = responseValues.response as AxiosResponse<PaginatedApiCollectionResponse<T>>;

  const { data: items, ...meta } = response?.data || {
    data: [],
    offsetValue: 0,
    limitValue: 50,
    totalPages: 0,
    hasNext: null,
    prevPage: null,
    nextPage: null,
    currentPage: null,
    draw: null,
    size: 0,
    totalCount: 0,
  };

  return [
    { items, ...meta },
    responseValues,
    execute,
    manualCancel,
  ];
}

export default useAxios;
