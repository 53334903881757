import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

// eslint-disable-next-line no-void
void i18n
  .use(resourcesToBackend((language, namespace, callback) =>
    import(`../../assets/locales/${language}/${namespace}.json`)
      .then(({ default: resources }) => {
        callback(null, resources[language] ? resources[language] : resources);
      })
      .catch((error) => {
        callback(error, null);
      })))
  .use(initReactI18next) // bind react-i18next to the instance
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['htmlTag', 'navigator'],
    },
    interpolation: {
      escapeValue: false, // react is xss safe
    },
    fallbackLng: ['en', 'nl'],
    load: 'languageOnly', // "en-US" becomes "en"
    ns: 'application',
    returnEmptyString: false,

    react: {
      // only enable if app is wrapped in <Suspense fallback={} />
      useSuspense: false,
    },
  });

// ref: https://github.com/jquense/yup/blob/master/src/locale.ts
setLocale({
  mixed: {
    default: 'validations.mixed.default',
    required: 'validations.mixed.required',
  },
  string: {
    email: 'validations.string.email',
    length: ({ length }) => ({ key: 'validations.string.length', values: { length } }),
    lowercase: 'validations.string.lowercase',
    matches: ({ regex }) => ({ key: 'validations.string.length', values: { regex } }),
    max: ({ max }) => ({ key: 'validations.string.max', values: { max } }),
    min: ({ min }) => ({ key: 'validations.string.min', values: { min } }),
    trim: 'validations.string.trim',
    uppercase: 'validations.string.uppercase',
    url: 'validations.string.url',
  },
  number: {
    min: ({ min }) => ({ key: 'validations.number.min', values: { min } }),
    max: ({ max }) => ({ key: 'validations.number.max', values: { max } }),
    lessThan: ({ less }) => ({ key: 'validations.number.lessThan', values: { less } }),
    moreThan: ({ more }) => ({ key: 'validations.number.moreThan', values: { more } }),
    positive: 'validations.number.positive',
    negative: 'validations.number.negative',
    integer: 'validations.number.integer',
  },
  array: {
    min: ({ min }) => ({ key: 'validations.array.min', values: { min } }),
    max: ({ max }) => ({ key: 'validations.array.max', values: { max } }),
  },
});

export default i18n;
