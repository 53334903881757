const parseExportedParams = () => {
  const $params = document.querySelector("meta[name='exported-params']");
  if (!$params) {
    return {};
  }
  try {
    const parsed = JSON.parse($params.getAttribute('data-params'));
    if (typeof parsed === 'object') {
      return parsed;
    }
  } catch {
    return {};
  }
};

export default parseExportedParams;
